import React from 'react'

export default function UpcomingEvents() {
    return (
        <div className="container mx-auto font-medium px-5 py-12">
            <div>
                
            </div>
        </div>
    )
}
